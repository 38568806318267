.homeContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)), url('../../media/images/homeBannerStadium.webp') no-repeat center;
    background-size: cover;
    gap: clamp(5px, 1vw, 15px);
    height: clamp(125px, 40vw, 500px);
}

.homeTitle{
    margin: 0;
    font-family: logirent;
    font-size: clamp(10px, 7vw, 60px);
    color: var(--colorGold);
}

.homeSubtitle{
    margin: 0;
    font-family: robotoRegular;
    font-size: clamp(10px, 5vw, 40px);
    color: var(--colorWhite);
}

.homeBtn{
    padding: clamp(3px, 1vw, 10px) clamp(5px, 1vw, 25px);
    font-size: clamp(5px, 2.5vw, 15px);
    border-radius: clamp(3px, 4vw, 10px);
    -webkit-border-radius: clamp(3px, 4vw, 10px);
    -moz-border-radius: clamp(3px, 4vw, 10px);
    -ms-border-radius: clamp(3px, 4vw, 10px);
    -o-border-radius: clamp(3px, 4vw, 10px);
    border: 2px solid var(--colorWhite);
    margin: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-family: robotoMedium;
    margin-top: clamp(15px, 7vw, 70px);
}

.homeBtn:hover{
    background-color: rgba(0, 0, 0, 0.8);
    border-color: var(--colorGold);
}
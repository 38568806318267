.footer{
    width: 100%;
    display: flex;
    flex-direction: column;
}

/* SECCION SUPERIOR DEL FOOTER */

.footerBannerCont{
    width: calc(100% - 50px);
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--colorGold);
    text-align: center;
    gap: 20px;
}

.footerBannerTextCont{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 5px;
    width: calc(100% / 3);
}

.footerBannerTextCont img{
    width: clamp(15px,5vw,30px);
    height: clamp(15px,5vw,30px);
}

.footerBannerTextCont span{
    font-size: clamp(10px,3vw,20px);
    font-family: robotoBold;
    color: var(--colorBlack);
}

/* SECCION MEDIA DEL FOOTER */

.footerCont{
    width: calc(100% - 50px);
    max-width: 1440px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--colorBlack);
    padding: 20px 0px 0px 0px;
    list-style: none;
}

.footerLogo{
    width: clamp(80px,12vw,180px);
}

.footerLogo img{
    width: 100%;
    height: 100%;
    object-fit: contain
}

.footerSection{
    padding: 20px 15px 30px 15px;
    flex-basis: 100%;
}

.footerSectionTitle{
    font-family: logirent;
    color: var(--colorGold);
    font-size: clamp(12px,2.5vw,18px);
    margin-bottom: 12px;
    margin-top: 0;
}

.footerSection h5:not(:first-child){
    margin-top: 50px;
}

.footerSection ul{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: clamp(5px,2vw,20px);
    flex-direction: column;
}

.footerSection a{
    color: var(--colorWhite);
    font-family: robotoRegular;
    font-size: clamp(10px,2.5vw,15px);
    text-align: left
}

/*----MOMENTANEO----*/
.tempImgCont img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
    
.tempImgCont{
    display: flex;
    width: 50px;
    height: 50px;
}

/* SECCION INFERIOR DEL FOOTER */

.footerWhiteBannerCont{
    width: calc(100% - 50px);
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--colorWhite);
    text-align: center;
    gap: 20px;
    font-size: clamp(20px,5vw,50px);
    font-family: logirent;
    color: var(--colorBlack);
}

@media only screen and (max-width:650px){
    .footerWebDisplay{
        display: none;
    }
}

@media only screen and (min-width:650px){
    .footerMobDisplay{
        display: none;
    }
}
/* DECLARACION DE FUENTES */

@font-face {
    font-family: robotoBlack;
    src: url(./media/fonts/Roboto-Black.ttf);
    font-display: block;
    font-weight: bold;
}

@font-face {
    font-family: robotoBold;
    src: url(./media/fonts/Roboto-Bold.ttf);
    font-display: block;
    font-weight: bold;
}

@font-face {
    font-family: robotoLight;
    src: url(./media/fonts/Roboto-Light.ttf);
    font-display: block;
    font-weight: bold;
}

@font-face {
    font-family: robotoMedium;
    src: url(./media/fonts/Roboto-Medium.ttf);
    font-display: block;
    font-weight: bold;
}

@font-face {
    font-family: robotoRegular;
    src: url(./media/fonts/Roboto-Regular.ttf);
    font-display: block;
    font-weight: bold;
}

@font-face {
    font-family: robotoThin;
    src: url(./media/fonts/Roboto-Thin.ttf);
    font-display: block;
    font-weight: bold;
}

@font-face {
    font-family: logirent;
    src: url(./media/fonts/LogirentRegular.ttf);
    font-display: block;
    font-weight: bold;
}

/* CLASES UNIVERSALES */

body{
    margin: 0;
    background-color: var(--colorBlack);
}

a, button{
    cursor: pointer;
    text-decoration: none;
}

/* VALORES GENERALES */

:root{
    --colorGold: #FAD300;
    --colorBlack: #010100;
    --colorWhite: #FDFCFC;
}

::selection{
    color: var(--colorBlack);
    background: var(--colorGold);
}
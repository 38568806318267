.countriesContainer{
    color: #FFFFFF;
    margin: auto;
    max-width: 95%
}

.countriesTitle{
    color: var(--colorGold);
    font-family: Logirent;
    font-size: 50px;
}

.tempCardCont{
    display: flex;
    justify-content: center;
    gap: 15px;
    margin: 0;
    flex-wrap: wrap;
    margin-bottom: 100px;
}

.tempCard{
    background-color: black;
    width: 220px;
    height: 220px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-decoration: none;
    overflow: hidden;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.cardImgCont{
    width: calc(100% - 20px);
    height: 130px;
    padding: 10px;
    opacity: 1;
    background-color: transparent;
}

.cardImg{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.cardTextCont{
    width: calc(100% - 20px);
    display: flex;
    align-items: flex-end;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 10px;
}

.cardText{
    width: 100%;
    text-align: center;
    color: var(--colorWhite);
    font-size: 18px;
    font-family: Logirent;
}

.tempCard:hover .cardImgCont{
    background-color: var(--colorWhite);
    background-image:  repeating-radial-gradient( circle at 0 0, transparent 0, var(--colorWhite) 40px ),  repeating-linear-gradient( var(--colorWhite), #b0b0b0 );
}

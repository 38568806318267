.loadingContainer{
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    background-color: var(--colorBlack);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 100px;
}

.loadingLogoCont{
    width: 25vw;
    min-width: 180px;
    max-width: 480px;
}

.loadingLogoCont img{
    width: 100%;
    object-fit: contain;
    animation: logoOpacity 5s ease-in-out infinite;
    -webkit-animation: logoOpacity 5s ease-in-out infinite;
    filter: drop-shadow(0 0 0.7rem var(--colorGold));
    -webkit-filter: drop-shadow(0 0 0.7rem var(--colorGold));
}

.loadingTextCont{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: logirent;
    color: var(--colorGold);
    font-size: 4vw;
    gap: 10px;
}

.loadingTextCont span {
    animation: textTransform 1s ease-in-out infinite;
    -webkit-animation: textTransform 1s ease-in-out infinite;
}

@keyframes logoRotation {
    0% {
        rotate: 0deg;
    }
    100% {
        rotate: 359deg;
    }
}

@keyframes logoOpacity {
    0% {
        opacity: 100%;
    }
    16% {
        opacity: 100%;
    }
    20% {
        opacity: 50%;
    }
    24%{
        opacity: 100%;
    }
    29% {
        opacity: 100%;
    }
    33% {
        opacity: 50%;
    }
    37%{
        opacity: 100%;
    }
    50% {
        opacity: 50%;
    }
    52% {
        opacity: 75%;
    }
    56% {
        opacity: 40%;
    }
    58% {
        opacity: 25%;
    }
    64% {
        opacity: 100%;
    }
    100%{
        opacity: 100%;
    }
}

@keyframes textTransform {
    0% {
        text-transform: capitalize;
        color: rgba(250, 211, 0, 0.5);
    };
}

@keyframes identifier {
    
}

.loadingTextCont span:nth-child(7) { animation-delay: 0s; }
.loadingTextCont span:nth-child(12) { animation-delay: 0.1s; }
.loadingTextCont span:nth-child(3) { animation-delay: 0.2s; }
.loadingTextCont span:nth-child(14) { animation-delay: 0.3s; }
.loadingTextCont span:nth-child(11) { animation-delay: 0.4s; }
.loadingTextCont span:nth-child(16) { animation-delay: 0.5s; }
.loadingTextCont span:nth-child(2) { animation-delay: 0.6s; }
.loadingTextCont span:nth-child(10) { animation-delay: 0.7s; }
.loadingTextCont span:nth-child(18) { animation-delay: 0.8s; }
.loadingTextCont span:nth-child(6) { animation-delay: 0.9s; }
.loadingTextCont span:nth-child(1) { animation-delay: 1s; }
.loadingTextCont span:nth-child(13) { animation-delay: 1.1s; }
.loadingTextCont span:nth-child(5) { animation-delay: 1.2s; }
.loadingTextCont span:nth-child(17) { animation-delay: 1.3s; }
.loadingTextCont span:nth-child(8) { animation-delay: 1.4s; }
.loadingTextCont span:nth-child(15) { animation-delay: 1.4s; }
.loadingTextCont span:nth-child(9) { animation-delay: 1.5s; }
.loadingTextCont span:nth-child(4) { animation-delay: 1.6s; }
.navbar{
    width: calc(100% - 50px);
    max-width: 100vw;
    padding: 0px 25px;
    background-color: var(--colorBlack);
    height: min(7vw, 75px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    font-family: robotoRegular;
    font-size: clamp(10px, 1.5vw, 20px);
    gap: 30px;
    position: sticky;
    top: 0px;
    background-color: var(--colorBlack);
    z-index: 99;
}

.navSection{
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10%;
}

.navLogoCont{
    margin-top: min(50px, 5vw);
    width: 100%;
}

.navLink{
    color: var(--colorWhite);
}

.navLink:hover{
    color: var(--colorGold);
}

.navLogo{
    width: min(9vw,100px);
    display: grid;
}

.navLogo img{
    width: 100%;
    height: 100%;
}

@media only screen and (max-width:600px){
    .navbar{
        display: none;
    }
}